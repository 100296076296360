<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="text-center">
        <div>
          Ihre E-Mail-Adresse konnte nicht in der Liste der berechtigten Nutzer gefunden werden. Wenden Sie sich bitte an Ihren Teamleiter.
        </div>
        <v-btn color="primary ma-3" to="/">OK</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
};
</script>
